<template>
  <div>
    <a-card :title="$t('打印配置')">
      <a-row :gutter="[12, 12]">
        <a-col :span="24">
          <a-button-group>
            <a-button @click="stockInOrderVisible = true">{{ $t("入库单打印配置") }}</a-button>
            <a-button @click="stockInOrderPreview">{{ $t("预览") }}</a-button>
          </a-button-group>
        </a-col>
        <a-col :span="24">
          <a-button-group>
            <a-button @click="shelveOrderVisible = true">{{ $t("上架单打印配置") }}</a-button>
            <a-button @click="shelveOrderPreview">{{ $t("预览") }}</a-button>
          </a-button-group>
        </a-col>
        <a-col :span="24">
          <a-button-group>
            <a-button @click="stockOutOrderVisible = true">{{ $t("送货单打印配置") }}</a-button>
            <a-button @click="stockOutOrderPreview">{{ $t("预览") }}</a-button>
          </a-button-group>
        </a-col>
        <a-col :span="24">
          <a-button-group>
            <a-button @click="pickingOrderVisible = true">{{ $t("拣货单打印配置") }}</a-button>
            <a-button @click="pickingOrderPreview">{{ $t("预览") }}</a-button>
          </a-button-group>
        </a-col>
        <a-col :span="24">
          <a-button-group>
            <a-button @click="stockCheckOrderVisible = true">{{ $t("盘点单打印配置") }}</a-button>
            <a-button @click="stockCheckOrderPreview">{{ $t("预览") }}</a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </a-card>

    <StockInOrderModal v-model="stockInOrderVisible" />
    <ShelveOrderModal v-model="shelveOrderVisible" />
    <StockOutOrderModal v-model="stockOutOrderVisible" />
    <PickingOrerModal v-model="pickingOrderVisible" />
    <StockCheckOrderModal v-model="stockCheckOrderVisible" />
  </div>
</template>

<script>
import printStockInOrder from "./printStockInOrder";
import printShelveOrder from "./printShelveOrder";
import printStockOutOrder from "./printStockOutOrder";
import printPickingOrder from "./printPickingOrder";
import printStockCheckOrder from "./printStockCheckOrder";

export default {
  components: {
    StockInOrderModal: () => import("./StockInOrderModal"),
    ShelveOrderModal: () => import("./ShelveOrderModal"),
    StockOutOrderModal: () => import("./StockOutOrderModal"),
    PickingOrerModal: () => import("./PickingOrerModal"),
    StockCheckOrderModal: () => import("./StockCheckOrderModal"),
  },
  data() {
    return {
      stockInOrderVisible: false,
      shelveOrderVisible: false,
      stockOutOrderVisible: false,
      pickingOrderVisible: false,
      stockCheckOrderVisible: false,
    };
  },
  computed: {
    stockInPrintConfig() {
      return this.$store.state.user.stockInOrderPrintConfig;
    },
    shelvePrintConfig() {
      return this.$store.state.user.shelveOrderPrintConfig;
    },
    stockOutPrintConfig() {
      return this.$store.state.user.stockOutOrderPrintConfig;
    },
    pickingPrintConfig() {
      return this.$store.state.user.pickingOrderPrintConfig;
    },
    stockCheckPrintConfig() {
      return this.$store.state.user.stockCheckOrderPrintConfig;
    },
  },
  methods: {
    stockInOrderPreview() {
      printStockInOrder(
        [
          {
            number: "RO2023010100000",
            warehouse_item: {
              number: "W001",
              name: "总仓",
            },
            client_item: {
              number: "C001",
              name: "默认货主",
            },
            counterparty_item: {
              number: "C001",
              name: "默认往来单位",
            },
            order_type_item: {
              name: "xxx",
            },
            external_number: "xxx",
            arrival_date: "2023-01-01",
            remark: "xxx",
            creator_item: {
              username: "admin",
              name: "admin",
            },
            create_time: "2023-01-01 00:00:00",
            receipt_material_items: [
              {
                material_item: {
                  name: "默认货品",
                  number: "G001",
                  barcode: "G001",
                  length: 10,
                  width: 10,
                  height: 10,
                  volume: 1,
                  weight: 10,
                  spec: "xxx",
                  unit: "xxx",
                },
                batch_number: "B001",
                total_quantity: 20.0,
              },
            ],
          },
        ],
        this.stockInPrintConfig
      );
    },
    shelveOrderPreview() {
      printShelveOrder(
        [
          {
            number: "SO2023010100000",
            receipt_order_item: {
              number: "RO2023010100000",
              counterparty_item: {
                number: "C001",
                name: "默认往来单位",
              },
              order_type_item: {
                name: "xxx",
              },
              external_number: "xxx",
              arrival_date: "2023-01-01",
              remark: "xxx",
            },
            warehouse_item: {
              number: "W001",
              name: "总仓",
            },
            client_item: {
              number: "C001",
              name: "默认货主",
            },
            creator_item: {
              username: "admin",
              name: "admin",
            },
            create_time: "2023-01-01 00:00:00",
            shelve_material_items: [
              {
                material_item: {
                  name: "默认货品",
                  number: "G001",
                  barcode: "G001",
                  length: 10,
                  width: 10,
                  height: 10,
                  volume: 1,
                  weight: 10,
                  spec: "xxx",
                  unit: "xxx",
                },
                batch_item: {
                  number: "B202309021651",
                  production_date: "2023-01-01",
                },
                total_quantity: 20.0,
                recommend_areas: ["A库区"],
              },
            ],
          },
        ],
        this.shelvePrintConfig
      );
    },
    stockOutOrderPreview() {
      printStockOutOrder(
        [
          {
            number: "DO2023010100000",
            warehouse_item: {
              number: "W001",
              name: "总仓",
            },
            client_item: {
              number: "C001",
              name: "默认货主",
            },
            counterparty_item: {
              number: "C001",
              name: "默认往来单位",
            },
            order_type_item: {
              name: "xxx",
            },
            external_number: "xxx",
            remark: "xxx",
            creator_item: {
              username: "admin",
              name: "admin",
            },
            customer_contact: "xxx",
            receiving_address: "xxx",
            contact_phone: "xxx",
            arrival_date: "2023-01-01",
            delivery_material_items: [
              {
                material_item: {
                  name: "默认货品",
                  number: "G001",
                  barcode: "G001",
                  length: 10,
                  width: 10,
                  height: 10,
                  volume: 1,
                  weight: 10,
                  spec: "xxx",
                  unit: "xxx",
                },
                batch_item: {
                  number: "B202309021651",
                  production_date: "2023-01-01",
                },
                total_quantity: 10.0,
              },
            ],
          },
        ],
        this.stockOutPrintConfig
      );
    },
    pickingOrderPreview() {
      printPickingOrder(
        [
          {
            number: "PO2023010100000",
            delivery_order_item: {
              number: "DO2023010100000",
              counterparty_item: {
                number: "C001",
                name: "默认往来单位",
              },
              order_type_item: {
                name: "xxx",
              },
              customer_contact: "xxx",
              receiving_address: "xxx",
              contact_phone: "xxx",
              arrival_date: "2023-01-01",
              external_number: "xxx",
              remark: "xxx",
            },
            warehouse_item: {
              number: "W001",
              name: "总仓",
            },
            client_item: {
              number: "C001",
              name: "默认货主",
            },
            creator_item: {
              username: "admin",
              name: "admin",
            },
            create_time: "2023-01-01 00:00:00",
            picking_material_items: [
              {
                material_item: {
                  name: "默认货品",
                  number: "G001",
                  barcode: "G001",
                  length: 10,
                  width: 10,
                  height: 10,
                  volume: 1,
                  weight: 10,
                  spec: "xxx",
                  unit: "xxx",
                },
                batch_item: {
                  number: "B202309021651",
                  production_date: "2023-01-01",
                },
                total_quantity: 10.0,
                recommend_location_items: [
                  {
                    location_number: "L001",
                  },
                ],
              },
            ],
          },
        ],
        this.pickingPrintConfig
      );
    },
    stockCheckOrderPreview() {
      printStockCheckOrder(
        [
          {
            number: "SC2023010100000",
            warehouse_item: {
              number: "W001",
              name: "总仓",
            },
            remark: "xxx",
            creator_item: {
              username: "admin",
              name: "admin",
            },
            create_time: "2023-09-09 21:41:06",
            stock_check_material_items: [
              {
                batch_item: {
                  number: "B202309021651",
                },
                location_item: {
                  number: "L001",
                },
                material_item: {
                  name: "G001",
                  number: "G001",
                  barcode: "G0001",
                  length: null,
                  width: null,
                  height: null,
                  volume: null,
                  weight: null,
                  spec: null,
                  unit: null,
                },
                status_display: "良品",
                book_quantity: 0.0,
              },
            ],
          },
        ],
        this.stockCheckPrintConfig
      );
    },
  },
};
</script>

<style scoped></style>
